import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
import Offline from "./Offline";
import { getParamValue } from "../helpers";

const StripchatLive = ({ params }) => {
  const param = (paramName) => getParamValue(params, paramName);

  const id = param("model");
  const url = `https://edge-hls.doppiocdn.com/hls/${id}/master/${id}.m3u8`;

  const [isError, setError] = useState(false);

  if (!id) {
    setError(true);
    console.log("missing model parameter");
  }

  const stopStreamAndRetry = () => {
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 5000);
  };

  return (
    <>
      {isError ? (
        <Offline />
      ) : (
        <ReactPlayer
          key={`react-player-m3u8-${id}`}
          url={url}
          width="100%"
          volume={0}
          height="100%"
          className="video-fit"
          onError={stopStreamAndRetry}
          playing
        />
      )}
    </>
  );
};

StripchatLive.propTypes = {
  params: PropTypes.any.isRequired,
};

export default StripchatLive;
