import React from 'react'
import PropTypes from 'prop-types'
import { PostScribe } from 'react-postscribe'
import { getParamValue } from '../helpers'

const AWEmpirePromo = ({ params }) => {
  const param = (paramName) => getParamValue(params, paramName)

  const markup = `<div data-awe-container-id="player_container"></div><script src="//aweproto.com/embed/tbplyrrnd/?psid=crakmedia1&pstool=421_3&sexualOrientation=${param('sexualOrientation')}&forcedPerformers[]=${param('forcedPerformers[]')}&tags=&primaryColor=8AC437&labelColor=212121&campaign_id=&accessKey=cafaf704e6f2f26ecb51dc423891d2f7&ms_notrack=1&c=player_container"></script>`

  return (<PostScribe html={markup} />)
}

AWEmpirePromo.propTypes = {
  params: PropTypes.any.isRequired,
}

export default AWEmpirePromo
