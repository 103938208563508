import React from 'react'
import PropTypes from 'prop-types'
import { PostScribe } from 'react-postscribe'
import { getParamValue } from '../helpers'

const AWEmpireLive = ({ params }) => {
  const param = (paramName) => getParamValue(params, paramName)

  const markup = `<div id="object_container"></div><script src="//aweproto.com/embed/lf?c=object_container&site=jasmin&cobrandId=&psid=crakmedia2&pstool=202_1&psprogram=revs&campaign_id=&category=girl&forcedPerformers[]=${param('forcedPerformers[]')}&vp[showChat]=false&vp[chatAutoHide]=false&vp[showCallToAction]=false&vp[showPerformerName]=false&vp[showPerformerStatus]=false&filters=&ms_notrack=1&subAffId="></script>`

  return (<PostScribe html={markup} />)
}

AWEmpireLive.propTypes = {
  params: PropTypes.any.isRequired,
}

export default AWEmpireLive
