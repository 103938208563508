import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import Offline from './Offline'
import { getParamValue } from '../helpers'


const M3U8 = ({ params }) => {
  const param = (paramName) => getParamValue(params, paramName)

  const url = param('url')

  const [isError, setError] = useState(false)
  console.log(url)
  if (!url) {
    setError(true)
    console.log('missing url parameter')
  }

  const stopStreamAndRetry = () => {
    setError(true)
    setTimeout(() => {
      setError(false)
    }, 5000)
  }

  return (
    <>
      {isError ? <Offline /> :
      <ReactPlayer
        key={`react-player-m3u8-${url}`}
        url={url}
        width="100%"
        volume={0}
        height="100%"
        className="video-fit"
        onError={stopStreamAndRetry}
        playing
      />}
    </>
  )
}

M3U8.propTypes = {
  params: PropTypes.any.isRequired,
}

export default M3U8
