export function getUrlParameters() {
  const urlParams = new URLSearchParams(document.location.search)
  return Array.from(urlParams.entries()).reduce((params, curr) => {
    params[curr[0]] = curr[1]
    return params
  }, {})
}

export function getParamValue(allParams, paramName) {
  return allParams[paramName] || ''
}
