import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Offline from './Offline'
import { getParamValue } from '../helpers'

const ImLive = ({ params }) => {
  const param = (paramName) => getParamValue(params, paramName)

  const [isError, setError] = useState(false)

  const modelID = param('model_id')
  const modelName = param('model_name')
  const workingServer = param('working_server')
  const roomID = param('room_id')

  const config = {
    player: {
      emoticons: true,
      volume: 0,
      enableVibeShowControls: true,
      team: 'IM2',
      postPay: false,
      currencySign: '$',
      iswhplr: false,
    },
    host: {
      id: modelID,
      room: roomID,
      name: modelName,
      workingServer,
    },
    guest: {
      id: 0,
    },
    gatewayType: 'none',
    videoType: 'html5-pls',
    environment: '',
    isrc: 0,
  }

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://j0.wlmediahub.com/App_Themes/api/scripts/video-chat.js'
    script.async = true
    document.body.appendChild(script)
    script.onload = () => {
      new VideoChat(config) // eslint-disable-line
    }
  })

  if (!modelID) {
    setError(true)
    console.log('missing model_id parameter')
  } else if (!modelName) {
    setError(true)
    console.log('missing model_name parameter')
  } else if (!workingServer) {
    setError(true)
    console.log('missing working_server parameter')
  } else if (!roomID) {
    setError(true)
    console.log('missing room_id parameter')
  }

  const stopStreamAndRetry = () => {
    setError(true)
    setTimeout(() => {
      setError(false)
    }, 5000)
  }

  return (
    <>
      {isError ? <Offline /> :
      <div
        id="video-container"
        onError={stopStreamAndRetry}
      />}
    </>
  )
}

ImLive.propTypes = {
  params: PropTypes.any.isRequired,
}

export default ImLive
