import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { getUrlParameters } from './helpers'
import AWEmpirePromo from './components/AWEmpirePromo'
import AWEmpireLive from './components/AWEmpireLive'
import StripchatLive from './components/StripchatLive'
import ImLive from './components/ImLive'
import M3U8 from './components/M3U8'
import './app.css'

function App() {
  const params = getUrlParameters()

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/awempirepromo" render={(props) => <AWEmpirePromo {...props} params={params} />} />
        <Route path="/awempirelive" render={(props) => <AWEmpireLive {...props} params={params} />} />
        <Route path="/stripchatlive" render={(props) => <StripchatLive {...props} params={params} />} />
        <Route path="/imLive" render={(props) => <ImLive {...props} params={params} />} />
        <Route path="/m3u8" render={(props) => <M3U8 {...props} params={params} />} />
      </Switch>
    </BrowserRouter>
  )
}

export default App
